/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { LottieAnimation } from '../LottieAnimation';
import styles from './signInWave.module.scss';

const classes = { root: styles.animationWrapper };

export const SignInWave = () => {
  return (
    <LottieAnimation
      src={'/animations/sign-in/sign_in_wave.json'}
      loop={4}
      renderer="svg"
      classes={classes}
    />
  );
};
