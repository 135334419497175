/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import { useXMPreviewState } from '@/stores/XMPreviewState';
import { isTrue } from '@/utils/isTrue';
import { stringifyUrl } from '@/utils/urlHelpers';
import { type AxiosInstance, type AxiosResponse } from 'axios';
import { type QueryClient, type QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { type HeaderData, type HeaderResponse } from '../interface';
import { dispatchEvent } from '@/utils/eventPublisher';
import { useYMMEAcesFlag } from '@/features/ymme/utils/ymmeFlag';

const getHeaderData = async (
  { queryKey }: QueryFunctionContext<ReturnType<typeof getHeaderDataKey>>,
  axiosInstance?: AxiosInstance,
  flags?: { ymmeAcesPostTransition: boolean; ymmeAcesEnabled: boolean }
) => {
  const url = flags?.ymmeAcesEnabled
    ? `${requestBaseURL}/ecomm/b2c/v3/page/header`
    : `${requestBaseURL}/ecomm/b2c/v2/page/header`;
  const [, options] = queryKey;
  const { previewDate, workspaceId } = options;
  const maybeXMPreviewUrl =
    workspaceId && previewDate
      ? stringifyUrl({
          url,
          query: { previewDate, workspaceId },
        })
      : url;
  const params = {
    hideXMContent: !flags?.ymmeAcesEnabled ? true : undefined,
  };
  const response = await getAxios(axiosInstance).get<HeaderResponse>(maybeXMPreviewUrl, { params });
  dispatchEvent('getHeader', response.data);

  return selector(
    response.data,
    response.headers,
    flags?.ymmeAcesEnabled,
    flags?.ymmeAcesPostTransition
  );
};

const selector = (
  responseResult: HeaderResponse,
  headers: AxiosResponse<HeaderResponse>['headers'],
  ymmeAcesEnabled?: boolean,
  ymmeAcesPostTransition?: boolean
): HeaderData => {
  let vehicleMap;

  if (ymmeAcesEnabled && ymmeAcesPostTransition) {
    // Flattening the object and making it look more like the data from v2
    // Top level vehicle properties
    const vehicleMapTopLayer = { ...responseResult.vehicleMap };
    delete vehicleMapTopLayer.acesVehicleAttributes;

    // ACES vehicle attributes without engine IDs
    const {
      driveTypeName: driveType,
      engineName: engine,
      makeName: make,
      modelName: model,
      subModelName: subModel,
      regionName: region,
      ...restAttributes
    } = responseResult.vehicleMap.acesVehicleAttributes ?? {};
    const acesVehicleAttributes = {
      region,
      driveType,
      engine,
      make,
      model,
      subModel,
      ...restAttributes,
    };
    delete acesVehicleAttributes.engineIds;

    // Engine specific IDs
    const engineIds = { ...(responseResult.vehicleMap.acesVehicleAttributes?.engineIds ?? {}) };

    // Merge all layers into a flat structure
    vehicleMap = Object.assign({}, vehicleMapTopLayer, acesVehicleAttributes, engineIds);
  }

  return {
    analyticsData: responseResult.analyticsData,
    isStagingApp: isTrue(responseResult.isStagingApp),
    headerPromo: responseResult.headerPromo?.contents,
    miniCartMap: responseResult.miniCartMap,
    myAccountMap: responseResult.myAccountMap,
    storeDetails: responseResult.storeDetails || undefined,
    storeNumber: responseResult.storeDetails?.storeNumber,
    vehicleMap: vehicleMap ? vehicleMap : responseResult.vehicleMap,
    apiResponseHeaders: {
      token_id: headers.token_id as string | undefined,
    },
    segmentMessageContent: responseResult.segmentMessageContent,
    miniCartSuccessNote: false,
    segments: responseResult.cmsSegmentedContent,
    savedOrderMap: responseResult.savedOrderMap,
  };
};

type Options = {
  previewDate: string | undefined;
  workspaceId: string | undefined;
};

export const HEADER_PRIMARY_KEY = 'header';

// TODO web5 tie header data with `vehicleId` so that it updates automatically whenever vehicle is changed.
// TODO web5 tie header data with `itemCount` from `miniCartMap` so that it updates automatically whenever item count in cart is changed.
// This should make us remove all the imperative `refetchHeaderData` calls.
// We should consider optimistic updates as well to avoid delays.
export const getHeaderDataKey = (options: Options) => [HEADER_PRIMARY_KEY, { ...options }] as const;

export function useHeaderData<SelectReturnType = HeaderData>(options?: {
  select?: (headerData: HeaderData) => SelectReturnType;
}) {
  const { previewDate, workspaceId } = useXMPreviewState();
  const { ymmeAcesPostTransition, ymmeAcesEnabled } = useYMMEAcesFlag();

  return useQuery({
    queryKey: getHeaderDataKey({ previewDate, workspaceId }),
    queryFn: (context: QueryFunctionContext<ReturnType<typeof getHeaderDataKey>>) =>
      getHeaderData(context, undefined, { ymmeAcesPostTransition, ymmeAcesEnabled }),
    staleTime: Infinity,
    select: (data) => {
      return options?.select ? options.select(data) : (data as SelectReturnType);
    },
  });
}

export const prefetchHeaderData = (
  queryClient: QueryClient,
  axiosInstance: AxiosInstance,
  flags: { ymmeAcesEnabled: boolean; ymmeAcesPostTransition: boolean }
) => {
  const options: Options = {
    previewDate: undefined,
    workspaceId: undefined,
  };

  return queryClient.prefetchQuery({
    queryKey: getHeaderDataKey(options),
    queryFn: (context: QueryFunctionContext<ReturnType<typeof getHeaderDataKey>>) =>
      getHeaderData(context, axiosInstance, flags),
  });
};

// Return header data from react query cache. Useful to access this data outside of a hook/component
export const getHeaderDataFromCache = (queryClient: QueryClient) => {
  return queryClient.getQueryData<HeaderData>(
    getHeaderDataKey({
      previewDate: undefined,
      workspaceId: undefined,
    })
  );
};
