/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { SignInWave } from '@/components/MyAccount/SignInWave';
import styles from './signinIcon.module.scss';
import azCommonStyles from '@/theme/globals.module.scss';
import cx from 'classnames';
import { useFeatureFlag } from '@/features/globalConfig';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';

type SignInIconProps = {
  isFixedHeader?: boolean;
  mainLabel: string;
  className: string;
};

export function SignInIcon({ mainLabel, isFixedHeader = false, className }: SignInIconProps) {
  const locale = useLocale();
  const isUS = locale === countryCodes.enUS;
  const LOTTIE_ANIMATIONS_ENABLED = useFeatureFlag('LOTTIE_ANIMATIONS_ENABLED') === 'true';

  return (
    <div
      className={cx({
        [styles.fixedSignInMobileIconAndLabel]: isFixedHeader,
        [className]: className,
      })}
    >
      {LOTTIE_ANIMATIONS_ENABLED && isUS ? (
        <SignInWave />
      ) : (
        <img src="/images/signin.svg" alt="" width={20} height={20} decoding="async" />
      )}
      <span className={cx(azCommonStyles['az-caption'], styles.signInMobileLabel)}>
        {mainLabel}
      </span>
    </div>
  );
}
