/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getFeatureFlag, type GlobalConfig, useFeatureFlag } from '@/features/globalConfig';

export const useYMMEAcesFlag = () => {
  const ymmeAcesEnabled = useFeatureFlag('YMME_ACES_ENABLED') === 'true';
  const ymmeAcesPostTransition = useFeatureFlag('YMME_ACES_POST_TRANSITION_PHASE') === 'true';
  const partsProFlag = useFeatureFlag('YMME_ACES_PARTS_PRO_FLAG') === 'true';

  return {
    ymmeAcesEnabled,
    ymmeAcesPostTransition,
    partsProFlag,
  };
};

export const getYMMEAcesFlag = (globalConfig: GlobalConfig | undefined) => {
  if (!globalConfig) {
    throw new Error('Global config is undefined');
  }
  const ymmeAcesEnabled = getFeatureFlag(globalConfig, 'YMME_ACES_ENABLED') === 'true';
  const ymmeAcesPostTransition =
    getFeatureFlag(globalConfig, 'YMME_ACES_POST_TRANSITION_PHASE') === 'true';
  const partsProFlag = getFeatureFlag(globalConfig, 'YMME_ACES_PARTS_PRO_FLAG') === 'true';

  return {
    ymmeAcesEnabled,
    ymmeAcesPostTransition,
    partsProFlag,
  };
};
